import Vue from 'vue'

var objects = [
    "TextField",
    "Numberic",
    "Button",
    "Schedule",
    "Call",
    "DatePicker",
    "Replacing",
    "Combobox",
    "ReplacingV2",
    "Select"
];

objects.forEach(element => {
    var name = element.includes("/") ? element.split("/")[element.split("/").length - 1] : element;
    Vue.component(name, () => import(`../components/${element}.vue`))
});