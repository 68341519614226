
import { io } from "socket.io-client";
import axios from 'axios';
const FileDownload = require('js-file-download');
var cookies = require('vue-cookies');

// import store from './store'

const api = "https://khpk.xenix19.ru";

// const api = "http://localhost:3020";
const front_key = "3JW5QKHIO24BSX036GW4";
const token = cookies.get("token")

const socket = io(`${api}`, {
  reconnectionDelayMax: 10000,
    auth: { token, front_key }
});



async function GET_ME() {
  try {
    const response = await axios.get(`${api}/api/me`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function GET_GROUPS() {
  try {
    const response = await axios.get(`${api}/api/groups`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function GET_STUDENTS() {
  try {
    const response = await axios.get(`${api}/api/students`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function GET_ALIASES() {
  try {
    const response = await axios.get(`${api}/api/aliases`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function GET_SUBJECTS() {
  try {
    const response = await axios.get(`${api}/api/subjects`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function GET_CALLS() {
  try {
    const response = await axios.get(`${api}/api/calls`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function GET_CHANGES() {
  try {
    const response = await axios.get(`${api}/api/changes`, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function EXPORT_XLS_CHANGES(date) {
  try {
    const response = await axios({ 
      url: `${api}/api/export_changes`,
      method: 'GET',
      responseType: 'blob',
      headers: { authorization: token, front_key: front_key }, params: {date} });
      FileDownload(response.data, `Замены ${date}.xlsx`);
      return true;
  } catch (error) {
    console.error(error);
    return error.response?.data || null;
  }
}

async function DO_LOGIN(password) {
  try {
    const response = await axios.post(`${api}/api/login/`, { password: password }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}
async function SAVE_SCHEDULE(group) {
  try {
    const response = await axios.post(`${api}/api/schedule/${group._id}`, { group }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

async function SAVE_CHANGES(changes) {
  try {
    const response = await axios.post(`${api}/api/changes/`, { changes }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

async function SAVE_CALLS(calls) {
  try {
    const response = await axios.post(`${api}/api/calls/`, { calls }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

async function POST_MESSAGE(message) {
  try {
    const response = await axios.post(`${api}/api/message/`, { message }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}
async function DELETE_STUDENT(id) {
  try {
    const response = await axios.post(`${api}/api/delete/`, { student: id }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}
async function SAVE_ALIASES(aliases) {
  try {
    const response = await axios.post(`${api}/api/aliases/`, { aliases }, { headers: { authorization: token, front_key: front_key } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

async function IMPORT_SCHEDULE_1C(fd, onlyNames) {
  try {
    const response = await axios.post(`${api}/api/schedule/import_bars/`, fd, { params: {onlyNames}, headers: { authorization: token, front_key: front_key, 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}


export default {
  rest: {
    SAVE_ALIASES,
    GET_STUDENTS,
    GET_GROUPS,
    GET_SUBJECTS,
    GET_ALIASES,
    EXPORT_XLS_CHANGES,
    GET_CHANGES,
    IMPORT_SCHEDULE_1C,
    SAVE_SCHEDULE,
    SAVE_CHANGES,
    GET_ME,
    DO_LOGIN,
    DELETE_STUDENT,
    SAVE_CALLS,
    GET_CALLS,
    POST_MESSAGE
  },
  socket
}