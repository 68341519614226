<template>

  <v-app style="app">
     <v-snackbar  v-model="snackbars.error">
      <v-row no-gutters align="center" style="gap: 32px;" justify="space-between">
        <span  style="white-space: pre-line"> {{snackbars.error_text}} </span>
        <v-btn color="red" variant="text" @click="snackbars.error = false"> ОК </v-btn>
      </v-row>
    </v-snackbar>

     <v-snackbar v-model="snackbars.developing">
      <v-row no-gutters align="center">
        <span>Данная функция в разработке </span>
        <v-spacer/>
        <v-btn color="red" variant="text" @click="snackbars.developing = false"> ОК </v-btn>
      </v-row>
    </v-snackbar>

     <v-snackbar small v-model="snackbars.saved">
      <v-row no-gutters align="center" style="gap: 8px">
        <span  style="white-space: pre-line"> {{snackbars.saved_text}}</span>
        <v-spacer/>
        <v-btn color="green" variant="text" @click="snackbars.saved = false"> ОК </v-btn>
      </v-row>
    </v-snackbar>

     <v-dialog v-model="auth.dialog" width="460px" persistent >
      <v-card class="pa-4">
        <h1 style="font-weight: 400;font-size: 32px;">Авторизация</h1>
        <TextField v-model="auth.password" placeholder="Пароль" type="password"></TextField>
        <span v-if="auth.failed" style="color: red; font-size: 14px">Неверный пароль</span>
        <Button class="mt-2" block @click="DoAuth">Войти</Button>
      </v-card>
    </v-dialog>


    <v-app-bar style="header" class="white">
      <v-row align="center" no-gutters style="max-width: 1660px; height: 100px" class="mx-auto">
        <img style="max-width: 84px; height: 80px" src="@/assets/logo.jpg"></img>
        <div class="ml-4"> 
          <p class="org-name" >ГБПОУ РХ "Хакасский политехнический колледж"</p>
          <p class="project-name" >Управление информационным ботом {{ $me ? `— ${$me.name}` : ""}}
            <span v-if="$me" @click="DeAuth" class="ml-4" style="color: blue; font-weight: 500 !important; cursor: pointer">Выход</span>
             <!-- {{$vuetify.breakpoint.name}} -->
             </p> 
        </div>
      </v-row>
    </v-app-bar>
    <v-main style="padding-top: 16px; width: 1660px;" class="mx-auto mb-16">

      <div class="front" v-if="students && groups_info && $me">

      <v-row no-gutters style=" width: 100%">
         <v-tabs
          v-model="tab"
        >
          <v-tab >Студенты</v-tab>
          <v-tab >Расписание</v-tab>
          <v-tab >Замены</v-tab>
          <v-tab>Расписание звонков</v-tab>
          <v-tab>Экстренная рассылка</v-tab>
          <v-tab @click="LoadAliases(undefined)">Сокращения названий</v-tab>
        </v-tabs>
      </v-row>

      <div class="wrap mt-8">
        <div v-if="tab === 0">
           <v-row no-gutters class="mt-6 mb-4">
          <h2> Всего — {{students.length}} {{d(students.length, ["студент","студента","студентов"])}}</h2>
        </v-row>
        
          <v-row>
            <v-col cols="6" lg="3">
              <Select placeholder="Выберете группу из списка" label="Группа" :items="groups" item_text="name" item_value="_id" small v-model="group_id"></Select>
            </v-col>
            <v-col cols="6" lg="3">
              <TextField placeholder="Фамилия, имя, телеграм.." label="Поиск" small v-model="search" ></TextField>
            </v-col>
          </v-row>

       
        <v-row no-gutters class="mt-2 mb-4">
          <h3 v-if="count_current_groupe_students != 0" style="font-weight: 300">В группе {{group_info.name}}  —  {{count_current_groupe_students}} {{d(count_current_groupe_students, ["студент","студента","студентов"])}}</h3>
          <h3 v-else style="font-weight: 300">Ещё никто не зарегистрировался под группой {{group_info.name}}</h3>
        </v-row>


          <v-row v-if="count_current_groupe_students != 0">
            <v-col cols="12">
              <v-data-table
                :search="search"
                :item-class="HeadManColor"
                :headers="headers"
                :items="list_students"
              >

               <template v-if="item" v-slot:item.username="{ item }">
                <a v-if="item?.username" target="_blank" :href="`https://t.me/${item.username}`"> @{{item.username}}</a>
                <a v-else target="_blank" :href="`tg://user?id=${item.id}`"> @{{item.tg_name}} ({{item.id}})</a>
               </template>

               <template v-slot:item.role="{ item }">

                <div v-if="item.role == 'student'">
                   <v-icon color="orange" size="24" v-if="group_info?.headman == item._id" class="mr-2">mdi-star</v-icon>
                   <span v-if="group_info?.headman == item._id">Староста</span>
                   <span v-else>Студент</span>
                </div>
                
                <span v-if="item.role == 'parent'">Родитель</span>
               </template>


               <template v-slot:item.date="{ item }">
                {{NormalizeDate(item.date)}}
               </template>

                <template v-slot:item.actions="{ item }">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click.native="RedirectToSendingMessage(item)" v-bind="attrs" v-on="on" style="cursor: pointer" class="mr-4" color="rgba(18, 77, 228, 1)">mdi-email</v-icon>
                    </template>
                    <span>Отправить сообщение</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="$me.access >= 1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click.native="DeleteStudent(item)" v-bind="attrs" v-on="on" style="cursor: pointer" class="mx-4" color="rgba(157, 0, 0, 1)">mdi-delete-forever</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
      
                </template>


                <template slot="no-data">
                  <div style="height: 150px; display: flex; align-items: center; justify-content: center">Студенты не найдены</div>
                </template>
                <template slot="no-results">
                  <div style="height: 150px; display: flex; align-items: center; justify-content: center">Студенты по таким параметрам не найдены</div>
                </template>

              </v-data-table>
          
            </v-col>
          </v-row>
        </div>

        <div v-if="tab === 1">
          <v-row align="center">
            <v-col cols="6" lg="3">
                <div style="display: flex;gap:8px">
                  <Select placeholder="Выберете группу из списка" label="Группа" :items="groups" item_text="name" item_value="_id" small v-model="group_id"></Select>
                  <v-tooltip bottom v-if="$me.access >= 1000">
                  <template v-slot:activator="{ on, attrs }">
                   <v-icon @click.native="ForceRefresh('SCHEDULE')"  v-bind="attrs"   v-on="on" class="mt-3" color="rgba(40, 53, 147, 1)" size="30">mdi-refresh</v-icon>
                  </template>
                  <span>Обновить (отмена)</span>
                </v-tooltip>
                </div>
            </v-col>
            <v-col cols="6" lg="3">
              <div v-if="$me.access >= 1000" class="mt-3" style="display: flex;  gap: 16px">
              <Button  :loading="loading.saving_schedule" @click="SaveSchedule" style="flex: 1">Сохранить</Button>
              <Button  :loading="loading.import_1c_schedule" @click="Import1C" style="flex: 1">Импорт из Барса</Button>
                <form style="display:none">
                  <input type="file" ref="file" v-on:change="handleFileUpload()" id="1cFileDialog" />
              </form>
        
              <!-- <Button @click="snackbars.developing = true" style="flex: 1" outlined>Проверить</Button> -->
              </div>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="6" lg="3" v-for="(n,i) in group_info.schedule.length"  :key="n._id">
              <Schedule :subjects="group_info.schedule[i]" :day="i"></Schedule>
            </v-col>

          </v-row>
        </div>

        <div v-if="tab === 2">
          <v-row align="center" class="mt-2">
            <v-col  cols="3" v-if="alternative_ui">
              <TextField class="mt-n3" placeholder="Начните вводить группу" label="Группа" small v-model="group_id_manual"></TextField>
            </v-col>
            <v-col cols="6" lg="3">
              <div style="display: flex; gap: 8px">
                <DatePicker :events="events"  class="mt-n4" placeholder="Дата" label="Дата" small v-model="search_date"></DatePicker>

                  <v-tooltip bottom v-if="$me.access >= 1000">
                  <template v-slot:activator="{ on, attrs }">
                   <v-icon @click.native="ForceRefresh('REPLACING')"  v-bind="attrs"   v-on="on" class="mt-3" color="rgba(40, 53, 147, 1)" size="30">mdi-refresh</v-icon>
                  </template>
                  <span>Обновить (отмена)</span>
                </v-tooltip>
              
              </div>
            </v-col>
            <v-col  cols="6" lg="3">
              <div class="mt-3" style="display: flex; gap: 16px; align-items: center">
              <Button v-if="$me.access >= 1000" :loading="loading.saving_changes" @click="SaveChanges"  style="flex: 1">Сохранить</Button>
              <Button v-if="$me.access >= 1000" :disabled="!Change || !Change.replacing.length != 0"  @click="ExportChangesXLS"  style="flex: 1">Экспорт Excel</Button>
              <!-- <Button @click="snackbars.developing = true" style="flex: 1" outlined>Проверить</Button> -->
              <!-- <v-checkbox v-model="alternative_ui" label="Альтернативный интерфейс"></v-checkbox> -->
              </div>
            </v-col>
          </v-row>
          <v-row v-if="alternative_ui">
            <div class="missing" v-if="list_replacing.length == 0 && date_exist">
              <span>Группы не найдены</span>
            </div>

            <div v-if="!date_exist" class="missing">
              <span>Замены не заданы на эту дату</span>
              <Button v-if="$me.access >= 1000" class="mt-4" outlined @click="CreateReplaceDay">Создать</Button>
            </div>
            <v-row v-if="date_exist">
            <!-- <v-col  cols="12" lg="4" v-for="(n) in list_replacing"  :key="n._id">
              <Replacing :search_date="search_date" :group="n"></Replacing>
            </v-col> -->
            </v-row>

          </v-row>

          <v-row no-gutters v-else class="mt-4">

             <v-col  cols="12" lg="6" >
              <ReplacingV2 :data="changes" :groups="groups" :search_date="search_date"></ReplacingV2>
            </v-col>

          </v-row>
        </div>

        <div v-if="tab == 3">
          <v-row class="mt-5 mb-16">
           <v-col cols="3" v-for="n in calls" :key="n._id" >
              <Call @DaySelected="CheckDayBussing(n, $event)" :data="n" @Remove="calls.splice(calls.indexOf(n), 1)"></Call>
             
           </v-col>
           <v-col cols="3" v-if="$me.access >= 1000">
             <div class="EmptyCall">
              <div class="wrap_EmptyCall" @click="AddNewCall">
                <v-icon size="64" color="#283593">mdi-plus</v-icon>
              </div>
              </div>
           </v-col>
          </v-row>

          <v-row no-gutters class="mt-8" justify="center" style="gap: 32px" align="center">
            <Button v-if="$me.access >= 1000" @click="SaveCalls" class="px-8" style="transform: scale(1.2)">Сохранить</Button>
              <v-tooltip bottom v-if="$me.access >= 1000">
                  <template v-slot:activator="{ on, attrs }">
                   <v-icon @click.native="ForceRefresh('CALLS')"  v-bind="attrs"   v-on="on" color="rgba(40, 53, 147, 1)" size="52">mdi-refresh</v-icon>
                  </template>
                  <span>Обновить (отмена)</span>
                </v-tooltip>
          </v-row>
        </div>

        <div v-if="tab == 4">
          <v-row  class="mt-5">
            <v-col cols="5">
              <h2>Сообщение</h2>
              <v-card class="pa-3 mt-4 nt-card"> 
                <TextField class="mb-2" label="От кого (необязательно)" placeholder="Имя Отчество" v-model="message.author"/>
                <span   style="font-size: 16px;color: #666666;font-weight: 400 !important;" >Текст сообщения</span>
                <v-textarea v-model="message.text" class="text-area-not mt-2" outlined ></v-textarea>

                <v-row no-gutters align=center style="gap: 16px">
                  <Button :loading="loading.send_message" @click="SendNotification" class="mt-n4">Отправить</Button>
                  <v-checkbox v-model="message.important" class="mt-n3" style="max-height: 30px" label="Отметить как важное"></v-checkbox>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4">
              <h2>Кому</h2>
              <v-card class="pa-3 mt-4 nt-card"> 

                <v-radio-group class="mt-0 mb-n4" v-model="message.type">
                  <v-radio v-if="$me.access >= 1000" label="Всем студентам" value="all_students"></v-radio>
                  <v-radio v-if="$me.access >= 1000" label="Всем старостам" value="all_headmans"></v-radio>
                  <v-radio label="Определённой группе" value="group"></v-radio>
                  <v-radio label="Определённому студенту" value="student"></v-radio>
                  <v-radio label="Старосте группы" value="headman"></v-radio>
                </v-radio-group>

                 <Select v-if="message.type == 'group'" class="mt-2" placeholder="Группа" label="Группа" :items="groups" item_text="name" item_value="_id" small v-model="message.group"></Select>
                 <Select v-if="message.type == 'headman'" class="mt-2" placeholder="Группа" label="Группа" :items="groups.filter(x => x.headman != undefined)" item_text="name" item_value="_id" small v-model="message.group"></Select>
                 <div v-if="message.type == 'student'">
                  <Select class="mt-2" placeholder="Группа" label="Группа" :items="groups" item_text="name" item_value="_id" small v-model="message.group"></Select>
                  <Select class="mt-n1" v-if="message.group" placeholder="Студент" label="Студент" :items="students_in_group" item_text="name" item_value="_id" small v-model="message.student"></Select>

                 </div>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-if="tab == 5"> 
            <v-row no-gutters justify="center" class="mt-16" v-if="aliases == undefined">
              <v-progress-circular 
                :width="10"
                size="150"
                color="#283593"
                indeterminate
              ></v-progress-circular>
            </v-row>

          <div  v-if="aliases != undefined">
            <v-row no-gutters align="center" style="gap: 8px">
              <Button :loading="loading.saving_aliases" @click="SaveAliases" class="mb-4">Сохранить</Button>
              <Button  :loading="loading.import_1c_schedule" @click="Import1C(true)" class="mb-4">Импорт из Барса</Button>

              <form style="display:none">
                  <input type="file" ref="file" v-on:change="handleFileUpload(true)" id="1cFileDialogOnlyNames" />
              </form>

                 <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                       <v-icon class="mt-n4" style="cursor: pointer" @click.native="AddAlias()" v-bind="attrs" v-on="on"   color="rgba(40, 53, 147, 1)" size="45">mdi-plus</v-icon>
                    </template>
                    <span>Добавить новое сокращение</span>
                  </v-tooltip>

            </v-row>
            <div v-for="(n,i) in aliases" :key="n._id" style="display: flex; gap: 8px;align-items:center">
                <span style="width:40px">{{i+1}}.</span>
                <TextField v-model="n.original"  placeholder="Название из Барс"></TextField>
                <v-icon color="black">mdi-arrow-right</v-icon>
                <TextField v-model="n.alias" :attention="CalculateAttention(n.alias.length)" placeholder="Сокращение"></TextField>
                <v-icon color="black" size="30" @click="RemoveAlias(n)">mdi-close</v-icon>
            </div>
          </div>
        </div>
      </div>
      </div>

      <v-row v-if="me && (!students || !groups_info )" no-gutters style="height: 400px; margin-top: 100px" align="center" justify="center">
          <v-progress-circular
      :width="10"
      size="150"
      color="#283593"
      indeterminate
    ></v-progress-circular>
      </v-row>

    </v-main >

        <v-footer padless color="gray" class="copyright">
          <v-col>
            <v-row no-gutters justify="center" align="center">
            <span>{{cv}}</span>
            </v-row>

            <v-row no-gutters justify="center" align="center">
            <span>2023 г.</span>
            </v-row>
            </v-col>

       
      </v-footer>

  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      tab: 0,
      aliases: undefined,
      alternative_ui: false,
      changes: undefined,
      message: {
        type: "all",
        text: undefined,
        author: undefined,
        important: false,
        group: undefined,
        student: undefined,
      },
      auth: {
        dialog: false,
        failed: false,
        password: undefined,
      },
      snackbars: {
        developing: false,
        saved: false,
        saved_text: undefined,
        error_text: undefined,
        error: false,
      },
      group_id: undefined,
      me: undefined,
      search_date: undefined,
      group_id_manual: undefined,
      loading: {
        saving_aliases: false,
        saving_schedule: false,
        saving_changes: false,
        saving_calls: false,
        send_message: false,
        import_1c_schedule: false,
      },
      calls: [
        {
          name: "Шаблон №1",
          schedule: [
            {
              call_1: undefined,
              call_2: undefined,
            },
            {
              call_1: undefined,
              call_2: undefined,
            },
          ],
          days: [true, false, false, false, false, false],
        },
      ],
      search: undefined,
      headers: [
        { text: "Роль", value: "role" },
        { text: "Фамилия", value: "surname" },
        { text: "Имя", value: "firstname" },
        { text: "Телеграм тег", value: "username" },
        { text: "Телеграм имя", value: "tg_name" },
        { text: "Дата вступления", value: "date" },
        { text: "Действие", value: "actions" },
      ],
      groups_info: undefined,
      students: undefined,
    };
  },
  watch: {
    search(w) {
      if (!(w?.length > 5)) return;
      w = w.toLowerCase();
      var finded = this.students.find((x) => {
        return (
          x.surname.toLowerCase().includes(w) ||
          x.firstname.toLowerCase().includes(w) ||
          x.tg_name.toLowerCase().includes(w) ||
          x.username.toLowerCase().includes(w)
        );
      });
      if (finded) this.group_id = finded.group;
    },
  },
  methods: {
    HeadManColor(item){ 
      if(this.group_info?.headman == item._id ) return 'headman-row';
      if(item.role == 'parent' ) return 'parent-row';
      return undefined;
    },
    AddAlias() {
      this.aliases.unshift({original: "", alias: ""});
    },
    RemoveAlias(n) {
      this.aliases.splice(this.aliases.indexOf(n), 1);
    },
    async SaveAliases() {
      this.loading.saving_aliases = true;
      var obj = {};

      this.aliases.forEach(el => {
        obj[el.original] = el.alias;
      });
      

       var response = await this.$API.SAVE_ALIASES(obj);

      if (response.success) {
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
          this.loading.saving_aliases = false;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
          this.loading.saving_aliases = false;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
          this.loading.saving_aliases = false;
      }
    },
    CalculateAttention(length) {
      if(length <= 16 ) return "rgba(0,100,0,0.5)";
      // if(length > 16 && length <= 25) return 	"rgba(75, 99, 0,0.5)";
      return "rgb(255,0,0,0.5)";

    },
    async LoadAliases(manual = undefined) {
      this.aliases = undefined;
      var aliases = manual || await this.$API.GET_ALIASES();
        var w = [];
      for (var key in aliases)
        if (aliases.hasOwnProperty(key)) w.push({ original: key, alias: aliases[key] });
      this.aliases = w;

    },
    async ForceRefresh(type) {
      if (type == "CALLS") this.calls = await this.$API.GET_CALLS();
      if (type == "SCHEDULE") this.groups_info = await this.$API.GET_GROUPS();
      if (type == "REPLACING") this.changes = await this.$API.GET_CHANGES();
    },
    d(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },

    async ExportChangesXLS() {
      var response = await this.$API.EXPORT_XLS_CHANGES(this.search_date);
      if (response === true) {
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    async handleFileUpload(onlyNames = false) {
      this.loading.import_1c_schedule = true;
      var file = this.$refs.file.files[0];
      
      if (file.type != "application/vnd.ms-excel") {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неподдерживаемый тип файла";
        this.loading.import_1c_schedule = false;
        return;
      }

      var form_data = new FormData();
      form_data.append("xls", file);

      var response = await this.$API.IMPORT_SCHEDULE_1C(form_data,onlyNames);

      if (response.success) {
        if(onlyNames) {
           this.snackbars.saved = true;
           this.snackbars.saved_text = "Импорт сокращений завершен";
           this.LoadAliases(response.text);
          //  this.aliases = response.text;
        }else {
          this.snackbars.saved = true;
          this.snackbars.saved_text = response.text;
        }
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
      this.loading.import_1c_schedule = false;
    },
    async Import1C(onlyNames = false) {
      if(!onlyNames)
      document.getElementById("1cFileDialog").click();
      else
      document.getElementById("1cFileDialogOnlyNames").click();
    },
    async DeleteStudent(stud) {
      var response = await this.$API.DELETE_STUDENT(stud._id);

      if (response.success) {
        this.students.splice(this.students.indexOf(), 1);
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    async SendNotification() {
      this.loading.send_message = true;
      var response = await this.$API.POST_MESSAGE(this.message);

      this.loading.send_message = false;
      if (response.success) {
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    RedirectToSendingMessage(student) {
      this.message.type = "student";
      this.message.student = student._id;
      this.message.group = student.group;
      this.tab = 4;
    },
    CheckDayBussing(call, w) {
      if (call.days[w] == true) call.days[w] = false;
      else {
        var ready = true;
        var f = this.calls.map((x) => x.days);
        for (var i = 0; i < f.length; i++) if (f[i][w] === true) ready = false;

        if (!ready) {
          this.snackbars.error = true;
          this.snackbars.error_text =
            "Конфликт между выбранным днем и другими шаблонами";
        } else {
          call.days[w] = true;
        }
      }
    },
    AddNewCall() {
      this.calls.push({
        name: `Шаблон №${this.calls.length + 1}`,
        schedule: [
          {
            call_1: undefined,
            call_2: undefined,
          },
          {
            call_1: undefined,
            call_2: undefined,
          },
        ],
        days: [false, false, false, false, false, false],
      });
    },
    DeAuth() {
      this.$cookies.set("token", undefined);
      window.location.reload();
    },
    async DoAuth() {
      this.auth.failed = false;
      var response = await this.$API.DO_LOGIN(this.auth.password);

      if (response.error) this.auth.failed = true;
      else {
        this.$cookies.set("token", response.token);
        window.location.reload();
      }
    },
    async SaveChanges() {
      this.loading.saving_changes = true;

      var response = await this.$API.SAVE_CHANGES(
        this.changes.find((x) => x.date == this.search_date)
      );

      this.loading.saving_changes = false;
      if (response.success) {
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    async SaveSchedule() {
      this.loading.saving_schedule = true;

      var response = await this.$API.SAVE_SCHEDULE(this.group_info);
      this.loading.saving_schedule = false;
      if (response.success) {
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    async SaveCalls() {
      this.loading.saving_calls = true;

      var response = await this.$API.SAVE_CALLS(this.calls);

      this.loading.saving_calls = false;

      if (response.success) {
        this.snackbars.saved = true;
        this.snackbars.saved_text = response.text;
      } else if (response.error) {
        this.snackbars.error = true;
        this.snackbars.error_text = response.error;
        return;
      } else {
        this.snackbars.error = true;
        this.snackbars.error_text = "Неизвестная ошибка";
      }
    },
    NormalizeDate(st) {
      var we = new Date(st).toLocaleString();
      return `${we.split(", ")[0]} в ${we.split(", ")[1]}`;
    },
    CreateReplaceDay() {
      if (this.groups_info.length > 0) {
        if (!this.groups_info[0].changes) {
          this.groups_info[0].changes = [
            {
              date: this.search_date,
              subjects: [],
            },
          ];
        } else {
          this.groups_info[0].changes.push({
            date: this.search_date,
            subjects: [],
          });
        }
      }
    },
  },
  async created() {
    if (this.$cookies.get("token") == undefined) {
      this.auth.dialog = true;
      return;
    }

    Vue.prototype.$me = await this.$API.GET_ME();
    this.changes = await this.$API.GET_CHANGES();

    if (!this.$me) {
      this.auth.dialog = true;
      this.$cookies.set("token", undefined);
      return;
    }

    Vue.prototype.$subjects = await this.$API.GET_SUBJECTS();
    this.students = await this.$API.GET_STUDENTS();
    this.groups_info = await this.$API.GET_GROUPS();
    this.calls = await this.$API.GET_CALLS();

    if (this.groups) {
      this.group_id = this.groups[0]._id;
      this.message.group = this.groups[0]._id;
    }

    var today = new Date();
    this.search_date = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    this.$socket.on("UpdateStudents", async () => {
      console.log("recived new students, updating..");
      this.students = await this.$API.GET_STUDENTS();
    });

    this.$socket.on("UpdateCalls", async () => {
      console.log("recived new calls, updating..");
      this.calls = await this.$API.GET_CALLS();
    });

    this.$socket.on("UpdateSchedule", async (id, new_schedule) => {
      console.log("recived new schedule, updating..");
      var group = this.groups_info.find((x) => x._id === id);
      if (group) group.schedule = new_schedule;
    });

    this.$socket.on("UpdateChanges", async (groups) => {
      console.log("recived new changes, updating..");
      this.changes = groups;
    });
  },
  computed: {
    Change() {
      var change = this.changes.find((x) => x.date == this.search_date);
      return change;
    },
    students_in_group() {
      if (!this.message.group) return [];
      return this.students
        .filter((x) => x.group == this.message.group)
        .map((y) => {
          return { _id: y._id, name: `${y.surname} ${y.firstname}` };
        });
    },
    count_current_groupe_students() {
      if (this.group_info)
        return this.students?.filter((x) => x.group == this.group_info._id)
          .length;
    },
    events() {
      var dates = [];
      this.changes.forEach((d) => {
        if (dates.indexOf(d.date) == -1 && d.replacing.length > 0)
          dates.push(d.date);
      });
      return dates;
    },
    date_exist() {
      return this.groups_info.find((x) =>
        x.changes.find((y) => y.date == this.search_date)
      );
    },
    cv() {
      return "Выполнил студент группы ИС(ПРО)-21 - Ремнев Павел Павлович";
    },
    groups() {
      if (this.groups_info)
        return this.groups_info.map((x) => {
          return {
            _id: x._id,
            name: x.name,
            headman: x.headman
          };
        });
    },
    list_students() {
      var data = this.group_info;
      if (!data) return [];
      return this.students.filter((x) => x.group === this.group_info._id);
    },
    group_info() {
      return this.groups_info.find((x) => x._id == this.group_id);
    },
    list_replacing() {
      if (!this.group_id_manual) return this.groups_info;
      return this.groups_info.filter((x) =>
        x.name.toLowerCase().includes(this.group_id_manual.toLowerCase())
      );
    },
  },
};
</script>

<style>
.headman-row {
  background: rgba(255, 191, 0, 0.3) !important;
}
.parent-row {
  background: rgba(26, 255, 0, 0.3) !important;
}

.v-icon.mdi-refresh {
  cursor: pointer;
  transition: transform 0.3s;
}
.v-icon.mdi-refresh:active {
  transform: scale(0.8);
}

.v-textarea fieldset {
  background: #f2f2f2 !important;
  border-radius: 8px;
  border: unset;
}
.nt-card {
  background: #ffffff !important;
  border: 1px solid #d2d2d2 !important;
  box-shadow: 0px 14px 38px -14px rgba(0, 0, 0, 0.25) !important;
  border-radius: 16px !important;
}
.EmptyCall {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap_EmptyCall {
  cursor: pointer;
  padding: 20px;
  border-radius: 16px;
  background: white;
  border: 0.668122px solid #d2d2d2;
  box-shadow: 0px 9.35371px 25.3886px -9.35371px rgba(0, 0, 0, 0.25);
}

.v-dialog {
  border-radius: 16px !important;
}
h2 {
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #000000 !important;
}
.v-application--wrap {
  width: 100vw !important;
}
.missing {
  flex-direction: column;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
}
.copyright {
  font-size: 12px;
  color: #5e5e5e;
}
* {
  font-family: Inter !important;
}

th {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  align-items: center !important;
  color: #666666 !important;
}
td {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  align-items: center !important;
  color: #2c2c2c !important;
}

.v-data-table--has-bottom
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:last-child:hover
  > td:first-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 2px solid #d9d9d9 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 50px !important;
}
.v-app-bar,
.v-toolbar__content {
  height: 100px !important;
  max-height: 100px !important;
  box-shadow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

.org-name,
.project-name {
  margin-bottom: 0 !important;
  text-align: left;
}

.project-name {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #5e5e5e;
}
.org-name {
  font-weight: 400;
  font-size: 32px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
