import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import api from './api.js'

import '@/components'
Vue.config.productionTip = false;
Vue.prototype.$subjects = undefined;
Vue.prototype.$me = undefined;
Vue.prototype.$API = api.rest;
Vue.prototype.$socket = api.socket;
Vue.use(require('vue-cookies'))

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.directive('click-outside', {
  bind () {
      this.event = event => this.vm.$emit(this.expression, event)
      this.el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.event)
  },   
  unbind() {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },

  stopProp(event) { event.stopPropagation() }
})
